import crypto from 'crypto';

export const generateAgentClassHash = (firstName: string, lastName: string): string => {
  const hash = crypto.createHash('md5').update(firstName + lastName).digest('hex');
  return `agent-${hash.substring(0, 8)}`;
};

export const generateAgentStyles = (
  agents: Array<{ firstName: string; lastName: string }>
): string => {
  return agents
    .map(agent => {
      const cls = generateAgentClassHash(agent.firstName, agent.lastName);
      return `.${cls}::before { content: "${agent.firstName} ${agent.lastName}";}`;
    })
    .join('\n');
};
