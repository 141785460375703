import { useState, useEffect } from 'react';
import { AGENT_SEARCH_PATH, EXP_COMMERCIAL_PATH, type AgentList } from 'utils/agent-endpoint';
import { GTM_CLICK_FEATURED_AGENT_CARD, GTM_CLICK_REGULAR_AGENT_CARD } from 'constants/events';
import { agentSearchIds } from 'constants/test-constants';
import { buildClassName } from 'utils/build-class-name';
import { trackEvent } from 'utils/google-tag-manager';
import { styleRichContent } from '../agent-profile';
import { useRouter } from 'next/router';
import { useIsMobile } from 'hooks/use-size-class';
import NextImage from 'next/image';
import { generateAgentClassHash } from 'utils/agent-name-obfuscator';
import styles from './style.module.scss';
export interface AgentCardProps {
  agent: AgentList;
  featured?: boolean;
  isCommercial?: boolean;
}

export default function AgentCard({ agent, featured = false, isCommercial = false }: AgentCardProps) {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [isMounted, setIsMounted] = useState(false);
  const [imgSrc, setImgSrc] = useState(
    agent.photo ? agent.photo : '/next/assets/images/agent-search/no-agent-photo.png'
  );

  const agentPathname = isCommercial ? EXP_COMMERCIAL_PATH : AGENT_SEARCH_PATH;
  const agentNameClass = generateAgentClassHash(agent.firstName, agent.lastName);

  // To avoid hydration errors run dangerouslySetInnerHTML only client side
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const styledBio = styleRichContent(agent?.bio);

  useEffect(() => {
    const newImgSrc = agent.photo ? agent.photo : '/next/assets/images/agent-search/no-agent-photo.png';
    setImgSrc(newImgSrc);
  }, [agent.photo]);

  const hasTags = (string: string) => {
    const pattern = /<[^>]*>/;
    return pattern.test(string);
  };

  const renderDescription = (description: string) => {
    if (hasTags(description) && isMounted) {
      return <div className={styles['agent-bio']} dangerouslySetInnerHTML={{ __html: description }} data-testid={agentSearchIds.agentCardBio}></div>;
    }
    if (styledBio) return <div className={styles['agent-bio']} dangerouslySetInnerHTML={{ __html: styledBio }} data-testid={agentSearchIds.agentCardBio}></div>;
    return <div className={styles['agent-bio']} data-testid={agentSearchIds.agentCardBio}>{description}</div>;
  };

  // this saves a prevPath for agent profile page to come back to the search page
  const handleCardClick = () => {
    if (featured) {
      trackEvent(GTM_CLICK_FEATURED_AGENT_CARD);
    } else {
      trackEvent(GTM_CLICK_REGULAR_AGENT_CARD);
    }
    const pathname = `${agentPathname}/${agent.firstName}-${agent.lastName}_${agent.id}`;
    router.push({
      pathname,
      query: { prevPath: window.location.href },
    }, pathname);
  };

  return (
    <a key={agent.id} className={buildClassName(styles['agent-card'], isCommercial && styles['is-commercial'])} onClick={handleCardClick} data-testid={agentSearchIds.agentCard}>
      <div className={styles['agent-photo-wrapper']}>
        <NextImage
          key={agent.id}
          className={styles['agent-photo']}
          fill
          src={imgSrc}
          onError={() => setImgSrc('/next/assets/images/agent-search/no-agent-photo.png')}
          alt={`${agent.firstName} ${agent.lastName}`}
          sizes="(max-width: 768px) 100vw, 150px"
          priority
        />
      </div>
      <div className={styles['agent-info']}>
        <p className={`${styles['agent-name']} ${agentNameClass}`} data-testid={agentSearchIds.agentCardName}></p>
        <p className={styles['agent-location']} data-testid={agentSearchIds.agentCardLocation}>{agent.city}, {agent.state}</p>
        {agent.bio && renderDescription(agent.bio)}
      </div>
      {isMobile &&
      <div className={styles['agent-arrow']}>
        <span>&rsaquo;</span>
      </div>
      }
    </a>
  );
}