import { createTheme } from '@mui/material/styles';

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#0C0F24',
      light: '#E8EDF5',
      dark: '#0C0F24',
    },
    secondary: {
      main: '#3D3F50',
      light: '#8ECFFF',
      dark: '#1B489B',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#0C0F24',
      secondary: '#5A5A5A',
    },
    divider: '#e6e6e6',
    error: {
      main: '#6D071A',
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
    h1: {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 300,
    },
    h2: {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 300,
    },
    h3: {
      fontFamily: 'Manrope, sans-serif',
      fontWeight: 300,
    },
    button: {
      fontWeight: 600,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480, // $breakpoint-mobile
      md: 768, // $breakpoint-tablet
      lg: 1200, // $max-width-app
      xl: 1920, // $breakpoint-large-desktop
    },
  },
  shape: {
    borderRadius: 24, // 24px for desktop default
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
          boxShadow: '0px 2px 4px 0px #00000014',
        },
        containedPrimary: {
          backgroundColor: '#0C0F24',
          '&:hover': {
            backgroundColor: '#3D3F50',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: '24px',
          boxShadow: '0 0 8px 0 rgba(0, 0, 0, .1)',
          [theme.breakpoints.down('md')]: {
            borderRadius: '16px',
          },
        }),
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: '#f6f7f8',
          '&::after': {
            background: 'linear-gradient(90deg, #f6f7f8, #edeef1, #f6f7f8)',
          },
          borderRadius: '24px',
          [theme.breakpoints.down('md')]: {
            borderRadius: '16px',
          },
        }),
      },
    },
  },
});
